// extracted by mini-css-extract-plugin
export var collaborators = "teaching-module--collaborators--NDW1a";
export var container = "teaching-module--container--FXkNJ";
export var credentials = "teaching-module--credentials--KYDXt";
export var defaultButton = "teaching-module--default-button--7XOmY";
export var expandButton = "teaching-module--expand-button--zgEV9";
export var github = "teaching-module--github--yLaFO";
export var header = "teaching-module--header--glRk-";
export var homeImage = "teaching-module--home-image--UekRX";
export var homeLinks = "teaching-module--home-links--d9Z2u";
export var homeTitle = "teaching-module--home-title--M6+ky";
export var html = "teaching-module--html--w52lw";
export var jobTitle = "teaching-module--job-title---WXPO";
export var journalTitle = "teaching-module--journal-title--CQCd3";
export var moreInfo = "teaching-module--more-info--WLCCp";
export var pageTitle = "teaching-module--page-title--bvN7i";
export var plusSign = "teaching-module--plus-sign--e8YLS";
export var projectBlock = "teaching-module--project-block--fR2HX";
export var projectImage = "teaching-module--project-image--S13vT";
export var projectInfo = "teaching-module--project-info--J3nle";
export var projectTitle = "teaching-module--project-title--w2JP5";
export var projectTitleNoLink = "teaching-module--project-title-no-link--3HoQV";
export var pubDate = "teaching-module--pub-date--eyAPL";